import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';

declare global {
	interface Window {
		//defined in index.html
		envConfigPromise: Promise<typeof import('../public/env.config.json')>;
	}
}

window.envConfigPromise.then((envConfig) => {
	const container = document.getElementById('root');

	createRoot(container!).render(
		<BrowserRouter>
			<App env={envConfig} />
		</BrowserRouter>
	);
});
