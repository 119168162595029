import React, { ReactElement, SetStateAction, useEffect, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { Loader } from './components/loader';
import { fetchSharedResource } from './helpers/requestHelper';
import { Dashboard } from './routes/dashboard';
import { Error } from './routes/error';
import { Expired } from './routes/expired';
import { Password } from './routes/password';
import { Passwordless } from './routes/passwordless';
import { track, eventNames } from'./helpers/userTracking';
import './App.css';

type DashboardParams = { dashboardID: string; };

const trackDashboardIncorrectPassword = () => track(eventNames.DASHBOARD_INCORRECT_PASSWORD);
const trackDashboardOpened = (passwordSet: ('Y' | 'N')) => track(eventNames.DASHBOARD_OPENED, { password_set: passwordSet });

const SharedDashboardContainer = (props: { sharedResourcesUrl: string }) => {
	const { dashboardID } = useParams<DashboardParams>();

	const [dashboardChecked, setDashboardChecked] = useState(false);
	const [dashboardValid, setDashboardValid] = useState(false);
	const [passwordNeeded, setPasswordNeeded] = useState(false);
	const [password, setPassword] = useState('');
	const [expired, setExpired] = useState(false);
	const [showDashboard, setShowDashboard] = useState(false);
	const [dashboardJSON, setDashboardJSON] = useState(null);

	useEffect(() => {
		fetchSharedResource({
			baseUrl: props.sharedResourcesUrl,
			resourceId: dashboardID,
			password,
		})
			.then((dashboardJSON) => {
				setDashboardJSON(dashboardJSON as SetStateAction<null>);
				setDashboardValid(true);

				if (password !== '' && passwordNeeded) {
					trackDashboardOpened('Y');
				}
			})
			.catch((error) => {
				console.error(error);
				if (error.response.status === 401) {
					if (passwordNeeded) {
						trackDashboardIncorrectPassword();
					}

					// Incorrect password
					setDashboardValid(true);
					setPasswordNeeded(true);
				} else if (error.response.status === 404) {
					// Asset expired
					setDashboardValid(true);
					setExpired(true);
				} else {
					console.error('Unrecognised error', error);
				}
			})
			.finally(() => {
				// We have successfully requested information on the dashboard
				setDashboardChecked(true);
			});
	}, [password]);

	// Handling password submission
	const handlePasswordSubmission = (password: string) => {
		setDashboardChecked(false);
		setPassword(password);
		setShowDashboard(true);
	};

	// Handling passwordless submission
	const handlePasswordlessSubmission = () => {
		setShowDashboard(true);
		trackDashboardOpened('N');
	};

	// Show the dashboard!
	if (showDashboard && dashboardJSON !== null) {
		return <Dashboard dashboardJSON={dashboardJSON} />;
	}

	// Have we asked the SDK about the dashboard yet?
	if (dashboardChecked) {
		// Is the dashboard in the system?
		if (dashboardValid) {
			// Has the dashboard expired?
			if (expired) {
				// Showing expired screen
				return <Expired />;
			} else if (passwordNeeded) {
				// Showing password required screen
				const hasIncorrectPassword = (passwordNeeded && password !== '');
				return <Password hasIncorrectPassword={hasIncorrectPassword} onSubmit={handlePasswordSubmission}/>;
			} else {
				// Showing passwordless screen
				return <Passwordless onSubmit={handlePasswordlessSubmission}/>;
			}
		} else {
			// Showing error screen
			return <Error />;
		}
	} else {
		// Still checking!
		return <Loader />;
	}
};

const App = ({ env }): ReactElement => (
	<Routes>
		{/* Dashboards */}
		<Route
			element={
				<SharedDashboardContainer
					sharedResourcesUrl={env.sharedResourcesUrl}
				/>
			}
			path='/dashboard/:dashboardID'
		/>
		{/* 404 */}
		<Route element={<Error />} path='*' />
	</Routes>
);

export { App };
