import React, { ReactElement } from 'react';
import DashboardsEngine from '@brandwatch/dashboards-engine';
import { IDashboard } from '@brandwatch/dashboards-engine/src/decs';
import { bcrToDETransform, IBCRDashboardConfiguration } from '@brandwatch/dashboards-engine/dist/helpers/transform';
import '../App.css';
import { getFormattedMetadata } from '../helpers/metadataFormatter';

type TBCRDashboardConfiguration = IBCRDashboardConfiguration['dashboard'];
interface IDashboardResource extends TBCRDashboardConfiguration {
	resourceCreatedAt?: string;
	resourceExpiresAt?: string;
}

const Dashboard = (props: { dashboardJSON: IDashboardResource }): ReactElement => {
	const { dashboardJSON } = props;
	const { resourceCreatedAt, dashboardTimezone, resourceExpiresAt } = dashboardJSON;

	const options = {
		'footer': {
			'show': true
		},
		'header': {
			description: getFormattedMetadata({
				creationDate: resourceCreatedAt || '',
				expirationDate: resourceExpiresAt || '',
				timezone: dashboardTimezone || 'Etc/UTC'
			}),
			'fixed': true,
			'show': true
		}
	};

	const dashboardConfiguration = { dashboard: dashboardJSON };
	const transformedDashboardJSON: IDashboard = bcrToDETransform(dashboardConfiguration);

	return (
		<DashboardsEngine
			dashboard={transformedDashboardJSON}
			options={options}
		/>
	);
};

export { Dashboard };
