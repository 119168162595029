type TError = Error & {
    response: object;
};

const fetchSharedResource = (
	{ baseUrl, resourceId, password }: {
		baseUrl: string,
		resourceId?: string,
		password: string,
	}
) => {
	if (!resourceId) {
		return Promise.reject();
	}

	const fullUrl = `${baseUrl}/shares/${resourceId}/resource`;
	const auth = `basic ${btoa(`:${password}`)}`;

	return fetch(fullUrl, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...(password !== ''
				? {
					Authorization: auth,
				}
				: {}),
		},
	})
		.then(checkStatus)
		.then((response) => response.json());
};

const checkStatus = (response: Response): Response => {
	if (response && response.status >= 200 && response.status < 300) {
		return response;
	}

	const error = new Error(response.statusText) as TError;
	error.response = response;
	throw error;
};

export { fetchSharedResource };
